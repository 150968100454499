/**
 * Service worker sourcce
 * This file is used by webpack to generate a service worker using workbox
 */
import { precacheAndRoute } from "workbox-precaching";
import { registerRoute } from "workbox-routing";
import {
   StaleWhileRevalidate,
   CacheFirst,
   NetworkFirst,
} from "workbox-strategies";

self.skipWaiting()
// Precahce webpack generated files
const gens = self.__WB_MANIFEST;
console.log(gens);
precacheAndRoute(gens);

// Network Only only use the Cache if we can't communicate with the server
registerRoute(
   ({ url }) => url.pathname === "/" || url.pathname === "/manifest.json" || url.pathname === "/appSDC.js",
   new NetworkFirst(),
);

registerRoute(
   ({ url }) => url.pathname === "/icons/180.png",
   async ({ request }) => {
      console.log("/icons/180.png")
      const icon = "calculator";//localStorage.getItem("icon") ?? "";
      if (!icon) return fetch(request);
      console.log(`/icons/icons8-${icon}-180.png`)
      return fetch(`/icons/icons8-${icon}-180.png`);
   }
)

// Cache First strategy for Assets that probably won't change:
registerRoute(
   new RegExp("/icons/.*\\.png"),
   new CacheFirst({ cacheName: "icons" }),
);
registerRoute(
   new RegExp("/js/.*\\.js"),
   new CacheFirst({ cacheName: "vendorJs" }),
);
registerRoute(new RegExp("/img/"), new CacheFirst({ cacheName: "images" }));
registerRoute(
   new RegExp("/webfonts/"),
   new CacheFirst({ cacheName: "webfonts" }),
);

// Stale While Revalidate for Assets that could change
registerRoute(
   new RegExp("/lib/.*\\.html"),
   new StaleWhileRevalidate({ cacheName: "applicationHtml" }),
);
registerRoute(
   new RegExp("/.*\\.css"),
   new StaleWhileRevalidate({ cacheName: "css" }),
);
registerRoute(
   new RegExp("/languages/.*\\.json"),
   new StaleWhileRevalidate({ cacheName: "languages" }),
);


